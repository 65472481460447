<template>
    <div class="scoreContainer">
        <div class="eventContainer">
            <div :class="{'eventItem':true,'choose':currentEvent===item,'unchoose':currentEvent!==item}" v-for="item in events" :key="item" @click="chooseEvent(item)">
                <img class="eventImg" :src="require('../../assets/'+handleEventName(item)+'.png')" v-if="currentEvent!==item"/>
                
                <img class="eventImg" src="../../assets/chooseEvent.png" v-else/>
                <div class="txt">{{handleEventName(item)}}</div>
            </div>
        </div>
        <div class="tip">
            <div class="item">
            This page shows the preliminary score. If any question error occurs, the score will need to be adjusted. Please find your final score and award on the score page after four objective tests.
            </div>
        </div>
        <div class="rankList">
            <div class="outContainer">
                <!-- <div>模拟考试仅供选手熟悉比赛系统及测试考试设备使用，模拟成绩暂不在【成绩排行】板块显示</div> -->
                <div v-for="(item,index) in rankList" :key="index" class="rankItem">
                    <div class="itemTxt">{{item.name}}</div>
                    <div v-for="x in 10" :key="x" :class="{'scoreItem':true,'gray':x%2!==0}">
                        <div class="container" v-if="item.rank[x-1]">
                            <!-- <img src="../../assets/jin.png" style="height:2vh;margin-right:3px" v-if="item.rank[x-1].rank===1">
                            <img src="../../assets/yin.png" style="height:2vh;margin-right:3px" v-else-if="item.rank[x-1].rank===2">
                            <img src="../../assets/tong.png" style="height:2vh;margin-right:3px" v-else-if="item.rank[x-1].rank===3"> -->
                            <div style="margin-right:3px">
                                <span class="circle">{{item.rank[x-1].rank}}</span>
                            </div>
                            <div style="width:60%;overflow:hidden;margin-right:2px">{{item.rank[x-1].account_name}}</div>
                            <div style="width:22%;overflow:hidden">{{item.rank[x-1].student_name}}</div>
                            <div style="width:12%">{{item.rank[x-1].score+'分'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="personalScore">
                <div class="txt">Individual Results</div>
                <div class="indiContainer">
                    <div v-for="(item,index) in personalScores" :key="index" class="item">
                        <div style="font-size:0.8vw;background: rgb(230,234,241);">{{item.name}}</div>
                        <div style="font-size:2vh;margin-top:1vh;">{{item.score?item.score:0}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {getScoresByEvent,getScoresSelf} from '../../api/index'
import {getUserId} from '../../utils/store'
export default {
    data(){
        return{
            events:[
                'Artithon - Academic Marathon in Art',
                'Biothon - Academic Marathon in Biology',
                'Chemithon - Academic Marathon in Chemistry',
                'Econthon - Academic Marathon in Economics',
                'Geothon - Academic Marathon in Geography',
                'Historithon - Academic Marathon in History',
                'Mathethon - Academic Marathon in Mathematics',
                'Physithon - Academic Marathon in Physics',
                'Psychothon - Academic Marathon in Psychology'
            ],
            currentEvent:'Artithon - Academic Marathon in Art',
            rankList:[],
            personalScores:[]
        }
    },
    mounted(){
        this.getScoresByEvent()
        this.getScoresSelf()
    },
    methods:{
        handleEventName(event){
            let event_array = event.split(" ")
            return event_array[0]
        },
        getScoresByEvent(){
            getScoresByEvent(this.currentEvent).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.rankList = res.data.data
                }
            })
        },
        getScoresSelf(){
            getScoresSelf(this.currentEvent,getUserId()).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.personalScores = res.data.data
                }
            })
        },
        chooseEvent(item){
            this.currentEvent = item
            this.getScoresByEvent()
            this.getScoresSelf()
        }
    }
}
</script>

<style lang="less">
.scoreContainer{
    .tip{
        width: 94%;
        color: #072D76;
       
        background: white;
        margin-top:1vh;
        .item{
            padding: 2px 4px;
            border: 4px solid #072D76;
            font-size: 0.8vw;
        }
    }
    .eventContainer{
        display: flex;
        flex-wrap: nowrap;
        width: 94%;
        .eventItem{
            cursor: pointer;
            width: 10%;
            height: 6vh;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-left:10px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            text-align: center;
            line-height: 2vh;
            .eventImg{
                width: 100%;
                height: 6vh;
            }
            .txt{
                position: absolute;
            }
        }
        .choose{
            color: white;
        }
        .unchoose{
            color: #072D76;
        }
    }
    .rankList{
        width: 94%;
        height: 60vh;
        margin-top:1vh;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #072D76;
        .outContainer{
            display: flex;
            justify-content: space-around;
            border: 4px solid #072D76;
            background: white;
            .rankItem{
                width: 25%;
                .itemTxt{
                    text-align: center;
                    font-size: 0.8vw;
                    height: 4vh;
                    line-height: 4vh;
                }
                .scoreItem{
                    height: 4vh;
                    .container{
                        width: 96%;
                        margin: 0 auto;
                        display: flex;
                        font-size: 0.6vw;
                        justify-content: center;
                        align-items: center;
                        height: 4vh;
                        line-height: 4vh;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
            }
        }
        .personalScore{
            background: white;
            border: 4px solid #072D76;
            padding-bottom: 1.2vh;
            margin-top:1vh;
            .txt{
                text-align: center;
                font-size: 2vh;
                height: 4vh;
                line-height: 4vh;
            }
            .indiContainer{
                display: flex;
                justify-content: space-around;
                align-items: center;
                text-align: center;
                .item{
                    width: 25%;
                }
                
            }
        }
    }
    .gray{
        background: rgb(230,234,241);
    }

}
</style>